import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Api from '../apis';
import * as liff from '../apis/liff';
import Box from '@material-ui/core/Box';
import Layout from '../components/Layout';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { getUrlParams } from '../helpers/location';
import { TimePicker } from '@material-ui/pickers';
import Header from '../components/Header';
import { navigate } from 'gatsby';
import Calendar from 'react-calendar';
import { packageMessage } from '../helpers/flexMessage';
import withAuth from '../hooks/withAuth';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: '100px',
  },
  textCenter: {
    textAlign: 'center',
  },
  textField: {
    width: '70%',
    marginBottom: 25,
    background: 'white',
    maxWidth: 350,
  },
  container: {
    maxWidth: 984,
    padding: 20,
    width: '100%',
  },
  img: {
    maxWidth: '100%',
  },
  salePrice: {
    color: '#ff8e17',
    fontWeight: 'bold',
    fontSize: 18,
    paddingBottom: 5,
  },
  normalPrice: {
    color: 'gray',
    textDecoration: 'line-through',
    fontSize: 14,
    paddingBottom: 5,
  },
  count: {
    color: 'black',
    fontSize: 14,
  },
  nextButton: {
    width: 150,
  },
  description: {
    textIndent: 30,
    color: 'black',
    background: '#dbe7f3',
    padding: 10,
    borderRadius: 4,
    whiteSpace: 'pre-wrap',
  },
}));

const Package = ({ location }) => {
  const classes = useStyles();
  const [date, setDate] = useState(formatDate(new Date()));
  const [product, setProduct] = useState(null);
  const [time, setTime] = useState(moment());
  const [remark, setRemark] = useState('');
  const [step, setStep] = useState(0);
  const query = getUrlParams(location.search);
  const { user } = useSelector(state => state.session);

  const { user_question_id = '', category_id = '', product_id = '' } = query;

  useEffect(() => {
    Api.getProduct(product_id).then(data => {
      setProduct(data);
    });
  }, []);

  function confirmPackage() {
    const dateTime = `${date} ${time.hour()}:${time.minute()}`;
    const payload = {
      remark,
      appointment_datetime: dateTime,
      user_questionnaire: user_question_id,
      product: product_id,
      user: user.id,
    };
    Api.postPackageOrder(payload)
      .then(data => {
        if (liff.isInClient()) {
          liff
            .sendMessages([
              {
                type: 'flex',
                altText: 'ส่งคำขอตรวจสุขภาพเรียบร้อย',
                contents: packageMessage(data),
              },
            ])
            .then(data => {
              liff.closeWindow();
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          alert(
            `ส่งคำขอตรวจสุขภาพเรียบร้อย\nนัดหมายวันที่: ${data.appointment_datetime_format}\nโปรแกรมตรวจสุขภาพ: ${data.product_data.name}`,
          );
          navigate('/');
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  function onDateChange(date) {
    const dateFormat = formatDate(date);
    setDate(dateFormat);
  }

  function formatDate(date) {
    if (!date) return '';
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  if (!product) return false;

  return (
    <Layout>
      {step === 0 && (
        <>
          <Header
            backAction={() => window.history.back()}
            text={product.name}
          />
          <Box justifyContent='center' display='flex'>
            <Box
              className={classes.container}
              flexDirection='column'
              justifyContent='center'
              display='flex'
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <img src={product.image.small} className={classes.img} />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 20 }}>
                  <Typography
                    classes={{ root: `${classes.count}` }}
                    component='p'
                  >
                    ราคา
                  </Typography>
                  <Typography
                    classes={{ root: classes.salePrice }}
                    component='h2'
                  >
                    ฿{product.sale_price}
                  </Typography>
                  <Typography
                    classes={{ root: classes.normalPrice }}
                    component='p'
                  >
                    ฿{product.normal_price}
                  </Typography>
                  <Typography
                    classes={{ root: `${classes.count}` }}
                    component='p'
                  >
                    {product.count} Views
                  </Typography>
                </Grid>
              </Grid>
              <h3 style={{ marginTop: 15, marginBottom: 0 }}>รายละเอียด</h3>
              <p className={classes.description}>{product.description}</p>
              <Box
                flexDirection='column'
                justifyContent='center'
                display='flex'
                alignItems='center'
              >
                <Button
                  onClick={() => setStep(1)}
                  color='primary'
                  variant='contained'
                  className={classes.nextButton}
                >
                  ถัดไป
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {step === 1 && (
        <>
          <Header backAction={() => setStep(0)} text={product.name} />
          <Box
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            display='flex'
          >
            <Box p={1}>
              <h3>กรุณาระบุวัน เวลา นัด</h3>
            </Box>
            <Box
              justifyContent='center'
              alignItems='center'
              display='flex'
              mb={4}
            >
              <Calendar onChange={onDateChange} locale='th-th' />
            </Box>
            <TimePicker
              clearable
              style={{ maxWidth: 350, width: '70%' }}
              ampm={false}
              value={time}
              onChange={setTime}
              inputVariant='outlined'
            />
            <br />
            <TextField
              variant='outlined'
              className={classes.textField}
              placeholder='หมายเหตุ'
              multiline
              rows={3}
              value={remark}
              onChange={e => setRemark(e.target.value)}
            />
            <Button
              onClick={confirmPackage}
              color='primary'
              variant='contained'
            >
              แจ้งความจำนง
            </Button>
          </Box>
        </>
      )}
      <br />
    </Layout>
  );
};

export default withAuth(Package);
