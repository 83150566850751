export const appointmentMessage = data => ({
  type: 'bubble',
  body: {
    type: 'box',
    layout: 'vertical',
    contents: [
      {
        type: 'text',
        text: 'คำขอการนัดหมาย',
        weight: 'bold',
        size: 'xl',
        color: '#008000',
      },
      {
        type: 'text',
        text: 'Mor Online',
        margin: 'md',
        size: 'xl',
        weight: 'bold',
      },
      {
        type: 'text',
        text: `ผู้ยื่นคำขอ: ${data.full_name || '-'}`,
        margin: 'md',
        wrap: true,
      },
      {
        type: 'text',
        text: `เบอร์โทร: ${data.phone}`,
        margin: 'md',
        wrap: true,
      },
    ],
  },
  footer: {
    type: 'box',
    layout: 'vertical',
    spacing: 'sm',
    contents: [
      {
        type: 'separator',
        margin: 'lg',
      },
      {
        type: 'text',
        text: `นัดหมายวันที่ ${data.appointment_datetime}`,
        margin: 'xxl',
        wrap: true,
        size: 'md',
        weight: 'bold',
      },
      {
        type: 'text',
        text: `ผู้ให้คำปรึกษา: ${data.consultant}`,
        wrap: true,
        margin: 'lg',
      },
      {
        type: 'text',
        text: `หมายเหตุ: ${data.remark || '-'}`,
        wrap: true,
        margin: 'lg',
      },
    ],
    flex: 0,
  },
});

export const packageMessage = data => ({
  type: 'bubble',
  body: {
    type: 'box',
    layout: 'vertical',
    contents: [
      {
        type: 'text',
        text: 'คำขอการตรวจสุขภาพ',
        weight: 'bold',
        size: 'xl',
        color: '#008000',
      },
      {
        type: 'text',
        text: 'Mor Online',
        margin: 'md',
        size: 'xl',
        weight: 'bold',
      },
      {
        type: 'text',
        text: `ผู้ยื่นคำขอ: ${data.user_data.fullname || '-'}`,
        margin: 'md',
        wrap: true,
      },
      {
        type: 'text',
        text: `เบอร์โทร: ${data.user_data.phone}`,
        margin: 'md',
        wrap: true,
      },
      {
        type: 'text',
        text: 'จะมีเจ้าหน้าที่ ติดต่อท่านภายใน 24 ชั่วโมง',
        margin: 'md',
        color: '#FF0000',
        wrap: true,
      },
    ],
  },
  footer: {
    type: 'box',
    layout: 'vertical',
    spacing: 'sm',
    contents: [
      {
        type: 'separator',
        margin: 'lg',
      },
      {
        type: 'text',
        text: `นัดหมายวันที่ ${data.appointment_datetime_format}`,
        margin: 'xxl',
        wrap: true,
        size: 'md',
        weight: 'bold',
      },
      {
        type: 'text',
        text: `โปรแกรมตรวจสุขภาพ: ${data.product_data.name}`,
        wrap: true,
        margin: 'lg',
      },
      {
        type: 'text',
        text: `หมายเหตุ: ${data.remark || '-'}`,
        wrap: true,
        margin: 'lg',
      },
    ],
    flex: 0,
  },
});
